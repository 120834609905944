import { ITransport, ReferralStats, Result, User } from './types';

export interface IUserService {
  setToken(token: string): void;
  resolveUser(refLink: string): Promise<Result<User>>;
  getReferralStats(): Promise<Result<ReferralStats>>
}

export class UserService implements IUserService {
  private transport: ITransport;
  
  constructor(transport: ITransport) {
    this.transport = transport;
  }

  setToken(token: string) {
    this.transport.setToken(token)
  }

  async resolveUser(refLink: string): Promise<Result<User>> {
    try {
      const response = await this.transport.request('resolveUser', { refLink: refLink })
      if (!response.ok) {
        return response
      }
      return { ok: true, value: response.value.user }
    } catch (error) {
      return { ok: false, error: { code: 500, message: error } }
    }
  }

  async getReferralStats(): Promise<Result<ReferralStats>> {
    try {
      const response = await this.transport.request('getReferralStats', {})
      if (!response.ok) {
        return response
      }
      return { ok: true, value: response.value.stats }
    } catch (error) {
      return { ok: false, error: { code: 500, message: error } }
    }
  }
}