import axios, { AxiosInstance } from "axios";
import { Result } from "./types";

export class Transport {
  private client: AxiosInstance;
  private token: string
  constructor(baseUrl: string) {
    this.client = axios.create({ baseURL: baseUrl })
    this.token = ''
  }

  setToken(token: string) {
    this.token = token
  }

  async request(method: string, params: object): Promise<Result<any>> {
    try {
      const response = await this.client.post('/', {
        jsonrpc: '2.0',
        method: method,
        params: params,
        id: 1,
      }, {
        headers: { 'X-User-Data': this.token }
      })
      
      if (response.data.error) {
        return { ok: false, error: response.data.error }
      }
      return { ok: true, value: response.data.result }
    } catch (err) {
      return { ok: false, error: { code: 500, message: err, data: [] } }
    }
  } 
}