import React, { useEffect, useRef, useState } from "react"

import './styles.css'
export interface ModalProps {
  children?: React.ReactNode
  visible: boolean
  onClose: () => void
}

const Modal: React.FC<ModalProps> = ({ children, visible, onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  return (
    <>
    <div className={`overlay ${visible ? 'active' : ''}`}></div>
      <div
        className={`modal rounded-t-3xl bg-neutral-800 ${visible ? 'active' : ''}`}
        ref={modalRef}
      >
        <div 
          className="absolute h-8 w-8 rounded-full bg-neutral-900 z-20 top-3 right-3 flex items-center justify-center cursor-pointer"
          onClick={onClose}
        >
          <i className="text-base p-2 font-light fas fa-close"></i>
        </div>
        {children}
      </div>
    </>
  )
}

export default Modal