import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { WebApp, WebAppUser } from "@twa-dev/types"
import Loader from '../../components/loader';
import { observer, inject } from 'mobx-react';
import { UserStore } from '../../stores/user';
import { debugMode } from '../../settings';

// Интерфейс контекста Telegram
interface ITelegramContext {
  webApp?: WebApp;
  loading: boolean
  showLoader: (val: boolean) => void
}

interface UserProps {
  userStore?: UserStore;
  children: React.ReactNode
}

// Создание контекста Telegram
const TelegramContext = createContext<ITelegramContext | undefined>(undefined);

const TelegramProvider: React.FC<UserProps> =  inject('userStore')(observer((props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [webApp, setWebApp] = useState<WebApp | null>(null);
  const { userStore } = props
  useEffect(() => {
    // Загрузка скрипта Telegram Web App
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-web-app.js';
    script.async = true;
    script.onload = async () => {
      const app: WebApp = (window as any).Telegram?.WebApp;
      if (app) {
        try {
          if (!debugMode) {
            app.expand();
            app.ready();
            app.requestWriteAccess()
            app.enableClosingConfirmation();
            app.setBackgroundColor('#000')
            app.setHeaderColor('#000'); // Устанавливаем цвет заголовка
            userStore?.setTGData(app.initDataUnsafe?.user as WebAppUser)
            userStore?.setRefLink(app.initDataUnsafe.start_param || '')
            userStore?.setToken(app.initData)
            await userStore?.loadUserData()
            setWebApp(app);
          }
          setLoading(false)
        } catch (err) {
          console.log('First load error:', err)
        }
      }
    };
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const value = useMemo(() => {
    return webApp ? { webApp } : {};
  }, [webApp]);

  const showLoader = (loading: boolean) => setLoading(loading);

  return (
    <TelegramContext.Provider value={{...value, loading, showLoader}}>
      { loading && (<Loader />) }
      {webApp && userStore?.user ? (props.children) : null }
      { debugMode && props.children }
    </TelegramContext.Provider>
  );
}))

const useTelegram = (): ITelegramContext => {
  const context = useContext(TelegramContext);
  if (!context) {
      throw new Error('useTelegram must be used within a TelegramProvider');
  }
  return context;
};


export { TelegramProvider, useTelegram };

