export function formatTime(unit: number) {
  return unit < 10 ? '0' + unit : unit;
}

export const getCounter = (remainingTime: number) => {
  if (remainingTime <= 0) {
    return null
  }
  
  const hours = Math.trunc(remainingTime / (1000 * 60 * 60));
  const minutes = Math.trunc((remainingTime % (1000 * 60 * 60) / (1000 * 60)));
  const seconds = Math.trunc((remainingTime % (1000 * 60)) / 1000);
  return formatTime(hours) + ":" + formatTime(minutes) + ":" + formatTime(seconds)
}

export const getRemainingTime = (end?: number): number => {
  const now = new Date().getTime()
  if (!end) return 0;
  
  const endTimestamp = new Date(end).getTime();
  if (now > endTimestamp) return 0;

  return endTimestamp - now;
}