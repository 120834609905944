import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import { TelegramProvider } from './providers/telegram';
import { RouterProvider } from 'react-router-dom';
import { router } from './router'

import { Provider } from 'mobx-react';
import stores from './stores';
import { maintenance } from './settings';
import Maintenance from './screens/maintenance';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-NMTL69NG9R');

const App = () => {
  if (maintenance) {
    return <Maintenance/>
  }

  return (
    <TelegramProvider>
      <RouterProvider router={router} />
    </TelegramProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider {...stores}>
      <App/>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
