import React, { useEffect } from 'react';
import { useTelegram } from "../../providers/telegram";
import { UserStore } from '../../stores/user';
import gameStore, { GameStore } from '../../stores/game';
import { observer, inject } from 'mobx-react';
import { Task } from '../../sevices/types';
import ReactGA from 'react-ga4';

interface TasksProps {
  userStore?: UserStore;
  gameStore?: GameStore;
}

const Tasks: React.FC<TasksProps> =  inject('userStore', 'gameStore')(observer((props) => {
  const { webApp } = useTelegram();
  
  useEffect(() => {
    if (!props.gameStore?.loadedTasks) {
      props.gameStore?.loadTasks()
      ReactGA.send({ hitType: "pageview", page: "/tasks", title: "Tasks" });
    }
  }, [props.gameStore?.loadedTasks])
  
  const open = async (item: Task) => {
    if (item.confirmed === 1) return;
    if (item.url) {
      if (item.url.search(/^https:\/\/t\.me/) === 0) {
        webApp && webApp.openTelegramLink(item.url)
      } else {
        webApp && webApp.openLink(item.url)
      }
    }
    await gameStore.confirmTask(item.id)
    ReactGA.event({ category: 'Tasks', action: 'confirm', label: item.title });
  }

  const isCanClaim = (task: Task): boolean => {
    return task.claimed === 0 
  }

  const isConfirmed = (task: Task): boolean => {
    return task.claimed === 1 && task.confirmed === 1
  }

  const isChecking = (task: Task): boolean => {
    return task.isChecking || false
  }

  return (
    <div className="flex flex-col justify-between h-full overflow-y-auto pt-4">
      <header className="px-6 text-center">
        <h1 className="xs:text-2xl text-2xl font-normal">Want to earn more?</h1>
        <h2 className="xs:text-base text-lg font-light">We’ll reward you immediately with points after each task completion.</h2>
      </header>

      <main className="flex flex-col flex-1 pb-[4rem] mt-4 rounded-t-2xl bg-neutral-900">
        <div className="flex flex-row p-6 align-center">
          <div className="flex-1 font-medium xs:text-lg text-xl">Tasks list</div>
        </div>

        <div className="flex flex-col px-6 pb-6">
          { props.gameStore?.tasks.map((it: Task) => (
            <button 
              className="flex flex-1 flex-row items-center bg-neutral-800 active:bg-neutral-700 rounded-lg px-3 py-3 mb-4 text-base"
              onClick={() => open(it)}
            >
              <div className="w-8 mr-4">
                { it.logo ? (
                  <img className="w-8" src={`./${it.logo}`} />
                ) : <img className="w-8" src={`./tasks.png`} /> }
              </div>
              <div className="flex-1">
                <div className="text-left font-medium">{it.title}</div>
                <div className="text-left font-light">{it.amount} Points</div>
              </div>
              <div className="text-center">
              { isChecking(it) && (
                <svg className="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              )}
              { isConfirmed(it) && (
                <div className="">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="green" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                  </svg>
                </div>
              ) }
              { isCanClaim(it) && (
                <svg fill="#94A3B8" height="1rem" width="1rem" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330">
                  <g strokeWidth="0"/>
                  <g  strokeLinecap="round" strokeLinejoin="round"/>
                  <g> <path d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z"/> </g>
                </svg>
              ) }
              </div>
            </button>
          ))}
        </div>
      </main>
    </div>
  );
}));

export default Tasks;