import { BoostAggregate, FarmingStatus, ITransport, Result, TaskAggregate } from './types';

export interface IGameService {
  getFarmingStatus(): Promise<Result<FarmingStatus>>
  startFarming(): Promise<Result<FarmingStatus>>
  grabGTons(): Promise<Result<FarmingStatus>>
  getTasks(): Promise<Result<TaskAggregate>>
  getBoosts(): Promise<Result<BoostAggregate>>
  buyBoost(id: number): Promise<Result<BoostAggregate>>
  confirmTask(id: number): Promise<Result<TaskAggregate>>
  checkTasks(ids: number[]): Promise<Result<TaskAggregate>>
}

export class GameService implements IGameService {
  private transport: ITransport;
  
  constructor(transport: ITransport) {
    this.transport = transport;
  }

  async getFarmingStatus(): Promise<Result<FarmingStatus>> {
    try {
      const response = await this.transport.request('getFarmingStatus', {})
      if (!response.ok) {
        return response
      }
      return { ok: true, value: response.value.farming }
    } catch (error) {
      return { ok: false, error: { code: 500, message: error } }
    }
  }

  async startFarming(): Promise<Result<FarmingStatus>> {
    try {
      const response = await this.transport.request('startFarming', {})
      if (!response.ok) {
        return response
      }
      return { ok: true, value: response.value.farming }
    } catch (error) {
      return { ok: false, error: { code: 500, message: error } }
    }
  }

  async grabGTons(): Promise<Result<FarmingStatus>> {
    try {
      const response = await this.transport.request('grabGTons', {})
      if (!response.ok) {
        return response
      }
      return { ok: true, value: response.value.farming }
    } catch (error) {
      return { ok: false, error: { code: 500, message: error } }
    }
  }

  async getTasks(): Promise<Result<TaskAggregate>> {
    try {
      const response = await this.transport.request('getTasks', {})
      if (!response.ok) {
        return response
      }
      return { 
        ok: true, 
        value: { 
          tasks: response.value.tasks, 
          success: response.value.success 
        }}
    } catch (error) {
      return { ok: false, error: { code: 500, message: error } }
    }
  }

  async getBoosts(): Promise<Result<BoostAggregate>> {
    try {
      const response = await this.transport.request('getBoosts', {})
      if (!response.ok) {
        return response
      }
      return { 
        ok: true, 
        value: { 
          boosts: response.value.boosts
        }}
    } catch (error) {
      return { ok: false, error: { code: 500, message: error } }
    }
  }

  async buyBoost(id: number): Promise<Result<BoostAggregate>> {
    try {
      const response = await this.transport.request('buyBoost', { id: id })
      if (!response.ok) {
        return response
      }
      return { 
        ok: true, 
        value: { 
          boosts: response.value.boosts
        }}
    } catch (error) {
      return { ok: false, error: { code: 500, message: error } }
    }
  }

  async checkTasks(ids: number[]): Promise<Result<TaskAggregate>> {
    try {
      const response = await this.transport.request('checkTasks', { ids: ids })
      if (!response.ok) {
        return response
      }
      return { 
        ok: true, 
        value: { 
          tasks: response.value.tasks, 
          success: response.value.success 
        }}
    } catch (error) {
      return { ok: false, error: { code: 500, message: error } }
    }
  }

  async confirmTask(id: number): Promise<Result<TaskAggregate>> {
    try {
      const response = await this.transport.request('confirmTask', { taskId: id })
      if (!response.ok) {
        return response
      }
      return { 
        ok: true, 
        value: { 
          tasks: response.value.tasks, 
          success: response.value.success 
        }}
    } catch (error) {
      return { ok: false, error: { code: 500, message: error } }
    }
  }
}