import React from 'react';

import { observer, inject } from 'mobx-react';
import { UserStore } from '../../stores/user';

interface MaintenanceProps {
  userStore?: UserStore;
}

const Maintenance: React.FC<MaintenanceProps> =  inject('userStore')(observer((props) => {
  
  return (
    <div className="flex flex-col flex-1 justify-center items-center h-full overflow-y-auto px-6 bg-neutral-900">
      <header className="pt-6 text-center">
        <h1 className="text-3xl font-normal">Maintenance Notice</h1>
        <h2 className="text-lg font-light pt-1">We are currently undergoing maintenance. Services will resume in one hour.</h2>
        <h2 className="text-lg font-light pt-16">Thank you for your understanding.</h2>
      </header>
    </div>
  );
}));

export default Maintenance;
