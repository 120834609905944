
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

import './styles.css'
import { useTelegram } from '../../providers/telegram';
const TabBar: React.FC = (props) => {
  const location = useLocation();
  const { loading } = useTelegram();
  if (loading) {
    return null
  }


  console.log('location', location)
  return (
    <nav className="tabbar h-16 px-6 z-30">
      <div className="flex w-full h-16 items-center justify-items-center bg-neutral-950 rounded-xl">
        <NavLink to="/" className={location.pathname === '/' ? "flex flex-1 flex-col items-center mt-1 text-slate-50" : "flex flex-1 flex-col items-center mt-1 text-slate-400"}>
          <i className="text-sm fas fa-home mb-1"></i>
          <span className="text-xs">Home</span>
        </NavLink>
        <NavLink to="/tasks" className={location.pathname === '/tasks' ? "flex flex-1 flex-col items-center mt-1 text-slate-50" : "flex flex-1 flex-col items-center mt-1 text-slate-400"}>
          <i className="text-sm fas fa-info-circle mb-1"></i>
          <span className="text-xs">Earn</span>
        </NavLink>
        <NavLink to="/referals" className={location.pathname === '/referals' ? "flex flex-1 flex-col items-center mt-1 text-slate-50" : "flex flex-1 flex-col items-center mt-1 text-slate-400"}>
          <i className="text-sm fas fa-users mb-1"></i>
          <span className="text-xs">Friends</span>
        </NavLink>
      </div>
    </nav>
  );
};

export default TabBar