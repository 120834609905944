import React, { useEffect, useState } from 'react';
import { useTelegram } from "../../providers/telegram";

import { observer, inject } from 'mobx-react';
import { UserStore } from '../../stores/user';
import { precision } from '../../settings';
import ReactGA from 'react-ga4';

interface ReferalsProps {
  userStore?: UserStore;
}

const Referals: React.FC<ReferalsProps> =  inject('userStore')(observer((props) => {
  const { webApp } = useTelegram();
  const { userStore } = props
  const [writingLink, setWritingLink] = useState(false)

  useEffect(() => {
    if (!userStore?.loadedStats) {
      userStore?.getReferralStats()
      ReactGA.send({ hitType: "pageview", page: "/referrals", title: "Referrals" });
    }
  }, [userStore?.loadedStats])

  const getInviteLink = () => {
    return `t.me/gtonapp_bot/gton?startapp=${userStore?.user?.refLink}`
  }

  const onClickHandler = (buttonId: string | undefined) => {
    const link = getInviteLink()
    const inviteLink = `https://t.me/share/url?url=${link}&text=Join%20me%20on%20GTon%20and%20let's%20earn%20together!%20Use%20my%20invite%20link%20to%20join%20the%20fun.%20%F0%9F%8C%9F`
    if (buttonId === 'copy') {
      navigator.clipboard.writeText(link).then(() => {
        alert('Link copied to clipboard!');
      }).catch(err => console.error('Copy link error:', err));
      ReactGA.event({ category: 'Referrals', action: 'copy' });
    }
    if (buttonId === 'send') {
      webApp?.openLink(inviteLink)
      ReactGA.event({ category: 'Referrals', action: 'send' });
    }
  }

  const showDialog = () => {
    webApp?.showPopup({
      title: 'Invite your friends',
      message: 'Send an invitation to your friends directly in Telegram or copy the link.',
      buttons: [
        { id: 'send', type: 'default', text: 'Send' },
        { id: 'copy', type: 'default', text: 'Copy Link' }
      ]
    }, onClickHandler)
    ReactGA.event({ category: 'Referrals', action: 'open_dialog' });
  }

  const copyLink = () => {
    navigator.clipboard.writeText(`https://${getInviteLink()}`)
    ReactGA.event({ category: 'Referrals', action: 'copy' });
    setWritingLink(true)
    const interval = setTimeout(() => {
      setWritingLink(false)
      clearTimeout(interval)
    }, 500)
  }

  const getTotalUSDT = () => {
    return ((userStore?.stats.referralsCountAtLevel1 || 0) * 0.03) + 
    ((userStore?.stats.referralsCountAtLevel2 || 0) * 0.01) +
    ((userStore?.stats.referralsCountAtLevel3 || 0) * 0.01)
  }
    
  return (
    <div className="flex flex-1 flex-col justify-between overflow-y-auto pt-4">
      <header className="px-6 text-center">
        <h1 className="xs:text-2xl text-2xl font-normal">Share your invitation link</h1>
        <h2 className="xs:text-base text-lg font-light">Invite your friends and earn more coins!</h2>
      </header>
 
      <div className="flex flex-col flex-1 my-4 p-6 rounded-2xl bg-neutral-900">
        <div className="flex flex-row mb-4">
          <div className="flex-1 xs:text-lg text-xl font-medium">Invite link:</div>
          <div>
            <button
              type="button"
              onClick={showDialog}
              className="rounded bg-orange-400 px-3 py-1 text-sm font-semibold text-white shadow-sm active:bg-orange-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-orange-400"
            >
              Send
            </button>
          </div>
        </div>
        <div>
          <button 
            className="py-2 px-4 w-full text-center bg-neutral-950 text-slate-300 rounded-lg text-sm font-light"
            onClick={copyLink}
          >
            { !writingLink ? 'Click to copy your link' : 'Copied' }
          </button>
        </div>
      </div>
      <main className="flex flex-col flex-1 pb-[4rem] rounded-t-2xl bg-neutral-900">
        <div className="flex flex-row p-6 align-center">
          <div className="flex-1 font-medium xs:text-lg text-xl">Your friends</div>
          <div className="font-light">
            <div className="bg-neutral-800 py-1 px-2 rounded-lg min-w-8 text-center flex flex-row items-center">
              <i className="fa fa-user text-sm mr-2 font-light"></i>
              <div>{ userStore?.stats.referralsCountAtLevel1 }</div>
            </div>
          </div>
          <div className="flex flex-row items-center ml-2 bg-neutral-800 py-1 px-3 rounded-lg">
            <img src="./tether.png" className="w-5 h-5 mr-2"/>
            <div className="flex-1 text-center text-sm font-light">{ getTotalUSDT().toFixed(2) }</div>
          </div>
        </div>
        <div className="flex flex-1 flex-col pb-6 px-6">

          <div className="flex flex-1 flex-row  bg-orange-500 rounded-lg px-3 py-3 mb-4">
            <div>
            <div className="font-medium xs:text-lg text-lg">Level 1</div>
              <div className="text-base">Earn an additional 10% of the GTons your friends farm.</div>
              <div className="flex flex-row items-center mt-4">
                <div className="flex flex-row items-center mr-3">
                  <i className="text-sm fas fa-user mr-1"></i>
                  <div className="text-base items-center">{ userStore?.stats.referralsCountAtLevel1 }</div>
                </div>
                <div className="flex flex-row items-center text-base mr-3">
                  <img className="h-5 w-5 mr-1" src="./tether.png" />
                  <div>{((userStore?.stats.referralsCountAtLevel1 || 0) * 0.03).toFixed(2)}</div>
                </div>
                <div className="flex flex-row items-center">
                  <img src="./coin.png" className="w-6 h-6 mr-1 mt-0.5" />
                  <div className="text-base items-center">
                    { userStore?.stats.earnedCountAtLevel1 === 0 ? 0 : (
                      ((userStore?.stats.earnedCountAtLevel1||0)/Math.pow(10, precision)).toFixed(precision)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-1 flex-row bg-neutral-800 rounded-lg px-3 py-3 mb-4">
            
            <div>
              <div className="font-medium text-lg">Level 2</div>
              <div className="text-base">Earn an additional 5% of the GTons farmed by the friends of your friends.</div>
              <div className="flex flex-row items-center mt-4">
                <div className="flex flex-row items-center mr-4">
                  <i className="text-sm fas fa-user mr-1"></i>
                  <div className="text-base items-center">{ userStore?.stats.referralsCountAtLevel2 }</div>
                </div>
                <div className="flex flex-row items-center text-base mr-3">
                  <img className="h-5 w-5 mr-1" src="./tether.png" />
                  <div>{((userStore?.stats.referralsCountAtLevel2 || 0) * 0.01).toFixed(2)}</div>
                </div>
                <div className="flex flex-row items-center">
                  <img src="./coin.png" className="w-6 h-6 mr-1 mt-0.5" />
                  <div className="text-base items-center text-orange-200">
                    { userStore?.stats.earnedCountAtLevel2 === 0 ? 0 : (
                      ((userStore?.stats.earnedCountAtLevel2||0)/Math.pow(10, precision)).toFixed(precision)
                    )}
                  </div>
                </div>
              </div>
              
            </div>
          </div>

          <div className="flex flex-1 flex-row bg-neutral-800 rounded-lg px-3 py-3 mb-4">
            <div>
              <div className="font-medium text-lg">Level 3</div>
              <div className="text-base">Continue to earn 1.5% of the GTons farmed by the next level of referrals.</div>
              <div className="flex flex-row items-center mt-4">
                <div className="flex flex-row items-center mr-4">
                  <i className="text-sm fas fa-user mr-1"></i>
                  <div className="text-base items-center">{ userStore?.stats.referralsCountAtLevel3 }</div>
                </div>
                <div className="flex flex-row items-center text-base mr-3">
                  <img className="h-5 w-5 mr-1" src="./tether.png" />
                  <div>{((userStore?.stats.referralsCountAtLevel3 || 0) * 0.01).toFixed(2)}</div>
                </div>
                <div className="flex flex-row items-center">
                  <img src="./coin.png" className="w-6 h-6 mr-1 mt-0.5" />
                  <div className="text-base items-center text-orange-200">
                    { userStore?.stats.earnedCountAtLevel3 === 0 ? 0 : (
                      ((userStore?.stats.earnedCountAtLevel3||0)/Math.pow(10, precision)).toFixed(precision)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}));

export default Referals;
