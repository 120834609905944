import { Transport } from "./transport";
import { IUserService, UserService } from "./userService";
import { apiHost } from '../settings'
import { GameService, IGameService } from "./gameService";
import { ITransport } from "./types";

let transportService: ITransport
let userServiceInstance: IUserService;
let gameServiceInstance: IGameService;

export const getTransport = (): ITransport => {
  if (!transportService) {
    transportService = new Transport(apiHost)
  }
  return transportService
}

export const getUserService = (): IUserService => {
  if (!userServiceInstance) {
    const transport = getTransport()
    userServiceInstance = new UserService(transport)
  }
  return userServiceInstance
}

export const getGameService = (): IGameService => {
  if (!gameServiceInstance) {
    const transport = getTransport()
    gameServiceInstance = new GameService(transport)
  }
  return gameServiceInstance
}