import React, { useState, useEffect } from 'react';
import { getCounter, getRemainingTime } from '../../utils';
import { Boost, FarmingStatus } from '../../sevices/types';
import { observer, inject } from 'mobx-react';
import { UserStore } from '../../stores/user';
import { GameStore } from '../../stores/game';
import { precision } from '../../settings';
import ReactGA from 'react-ga4';
import "./styles.css"
import Modal from '../../components/modal';

interface HomeProps {
  userStore?: UserStore;
  gameStore?: GameStore;
}

const Home: React.FC<HomeProps> =  inject('userStore', 'gameStore')(observer((props) => {
  const { userStore, gameStore } = props
  const [ counter, setCounter ] = useState<string|null>(getCounter(0))
  const [isVisibleBosts, showBosts] = useState<boolean>(false)
  const [buyingBoost, setBuyStatus] = useState<boolean>(false)
  
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });
  }, []);

  useEffect(() => {
    gameStore?.fetchFarmingState()
    gameStore?.loadBoosts()
  }, [])
  
  useEffect(() => {
    if (!gameStore?.farmingStatus) return;
    
    const remainingTime = getRemainingTime(gameStore.farmingStatus?.endTime)
    setCounter(getCounter(remainingTime))
    
    const interval = setInterval(() => {
      let remainingTime = getRemainingTime(gameStore.farmingStatus?.endTime)
      if (remainingTime <= 0) {
        clearInterval(interval);
      }
      const time = remainingTime <= 0 ? 0 : remainingTime
      setCounter(getCounter(time))
    }, 1000);

    return () => clearInterval(interval);
  }, [gameStore?.farmingStatus]);

  const startFarming = async () => { 
    gameStore?.startFarming() 
    ReactGA.event({ category: 'Game', action: 'start_farming' });
  }

  const grab = async () => {
    const amount = (userStore?.user?.power || 0) * (gameStore?.farmingStatus?.total || 0)
    gameStore?.grab().then(() => userStore?.incrementBalance(amount))
    ReactGA.event({ category: 'Game', action: 'grab_tokens', value: amount });
  }

  const getBalance = () => {
    if (!gameStore?.farmingStatus) return 0;
    if (!userStore?.user) return 0;
    const remainingTime = getRemainingTime(gameStore.farmingStatus?.endTime)
    const time = Math.trunc(remainingTime / 1000)
    return (((gameStore.farmingStatus.total - time) * userStore?.user.power) / Math.pow(10, precision)).toFixed(precision)
  }

  const getProgressStyle = (): object => {
    const result = { width: '0%' }
    if (gameStore?.farmingStatus && userStore?.user) {
      const remainingTime = getRemainingTime(gameStore.farmingStatus?.endTime)
      const time = Math.trunc(remainingTime / 1000)
      const delta = gameStore.farmingStatus.total - time
      result.width = `${(delta / gameStore.farmingStatus.total) * 100}%`
    }
    
    return result
  }

  const isAvailableToEarn = (farmingStatus: FarmingStatus|undefined): boolean => {
    if (!farmingStatus) {
      return true
    }
    return farmingStatus.grabed === 1 && new Date().getTime() > farmingStatus.endTime
  }

  const isAvailableToGrab = (farmingStatus: FarmingStatus|undefined): boolean => {
    if (!farmingStatus) {
      return false
    }
    return farmingStatus.grabed === 0 && new Date().getTime() > farmingStatus.endTime
  }

  const showBoostMenu = () => { 
    if (!isVisibleBosts) {
      ReactGA.event({ category: 'Boosts', action: 'open_boosts_dialog' });
    }
    showBosts(!isVisibleBosts)
  }

  const upgradeBoost = (boost: Boost|undefined) => {
    if (!boost) return;
    if (boost.coins && boost.coins > (userStore?.user?.balance || 0)) {
      return;
    }
    setBuyStatus(true)
    gameStore?.buyBoost(boost).finally(() => setBuyStatus(false)).then(() => showBoostMenu())
    ReactGA.event({ category: 'Boosts', action: 'buy_boosts_dialog' });
  }
  
  const currentBoost = gameStore?.boosts.find(it => it.id === userStore?.user?.boostId)
  const currentPower = gameStore?.boosts.reduce((res, it) => {
    if (currentBoost && it.id <= currentBoost.id) {
      res += it.power
    }
    return res
  }, 0)
  
  const nextBoostLevel = gameStore?.boosts.find(it => {
    if (!currentBoost) {
      if (it.id == 1) {
        return true
      }
      return false
    }
    const nextId = currentBoost.id + 1
    if (it.id === nextId) {
      return true
    }
    return false
  })

  return (
    <div className='absolute overflow-hidden w-full h-full'>
      <Modal visible={isVisibleBosts} onClose={showBoostMenu}>
        <div className="relative">
          <img src="./boost_bg1.webp" className="boost_bg"/>
          <div className="relative">
            <div className='text-center mb-4 px-6 pt-6 mb-6'>
              <div className="text-3xl font-medium">Boost your mining</div>
              <div className="text-xl">Increase mining speed and duration</div>
            </div>
            
            <div className='px-6 text-base'>
              <div className='w-full bg-neutral-900 rounded-xl mb-12'>
                <div className="flex flex-row p-3.5">
                  <div className='flex-1'><span className='text-slate-300'>Current:</span> <span className='font-medium'>{ currentBoost?.title }</span></div>
                  <div className="flex flex-row items-center">
                    <div className="w-5 ml-4 mr-1"><img src="./kirk2.png" /></div>
                    <div>{ currentPower }</div>
                  </div>
                  <div className="flex flex-row items-center">
                    <div className="w-5 ml-4 mr-1"><img src="./time.png" /></div>
                    <div>{currentBoost && currentBoost.time / (60*60)} hours</div>
                  </div>
                </div>
              </div>
            </div>

            { nextBoostLevel && (
              <div className='w-full bg-neutral-900 rounded-t-2xl p-6 pb-8'>
                <div className='mb-6'>
                  <div className='text-center text-xl font-medium'>Upgrade to {nextBoostLevel?.title}</div>
                </div>
                <div className="flex flex-row p-3.5 bg-neutral-800 rounded-2xl mb-4">
                  <div className='flex-1'><span className='text-slate-300'>New:</span> <span className='font-medium'>{nextBoostLevel?.title}</span></div>
                  <div className="flex flex-row items-center">
                    <div className="w-5 ml-4 mr-1"><img src="./kirk2.png" /></div>
                    <div>+{nextBoostLevel.power}</div>
                  </div>
                  <div className="flex flex-row items-center">
                    <div className="w-5 ml-4 mr-1"><img src="./time.png" /></div>
                    <div>{(nextBoostLevel?.time || 0)/(60*60)} hours</div>
                  </div>
                </div>
                <button 
                  className={`${buyingBoost ? 'animate-pulse': ''} px-3.5 py-3.5 text-lg font-medium w-full text-center bg-orange-400 rounded-2xl text-white shadow-sm active:bg-orange-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-orange-400`}
                  onClick={() => upgradeBoost(nextBoostLevel)}
                  disabled={buyingBoost || nextBoostLevel.coins > (userStore?.user?.balance || 0)}
                >
                  Upgrade for { nextBoostLevel?.coins ? `${(nextBoostLevel.coins / Math.pow(10, precision)).toFixed(precision)} GTon's` : `${nextBoostLevel?.ton} Ton` }
                </button>
                { nextBoostLevel?.coins && nextBoostLevel.coins > (userStore?.user?.balance || 0) && (
                  <div className='px-6 pt-6 text-center text-slate-300'>
                    You do not have enough coins. Please try again later.
                  </div>
                ) }
              </div>  
            ) }
          </div>
        </div>
      </Modal>
      <div className="flex flex-1 flex-col relative h-full overflow-y-auto">
        <div className="flex my-4 px-4 bg-black">
          <div className="h-14 w-14 overflow-hidden rounded-md mr-2">
            <img src="./man1.webp" />
          </div>
          <div className="flex flex-1 flex-row h-14 align-middle items-center bg-neutral-900 rounded-md">
            <div className="text-md font-normal leading-5 p-3"><b>{userStore?.tgUser?.first_name}</b> (Miner)</div>
          </div>
          <div className='flex items-center ml-2'>
          <button className="h-14 pl-4 pr-5 flex flex-col items-center justify-center bg-neutral-900 rounded-md" onClick={showBoostMenu}>
            <img className='w-5 h-5' src='./r.png'/>
            <div className='font-light text-xs'>Boost</div>
          </button>
          </div>
        </div>

        <main className="flex flex-1 flex-col pt-6 pb-[5rem] bg-neutral-900 items-center rounded-t-2xl">
          
          <div className="flex h-12 bg-neutral-800 border border-gray-800 rounded-2xl justify-center items-center">
            <div className="w-6 ml-4 mr-1"><img src="./coin.png" /></div>
            <div className="text-lg font-normal">{((userStore?.user?.balance||0)/Math.pow(10, precision)).toFixed(precision)}</div>
            <div className="w-5 ml-4 mr-2"><img src="./kirk2.png" /></div>
            <div className="text-lg font-normal mr-6">{userStore?.user?.power||0}</div>
          </div>

          <div className="flex flex-col flex-1 w-full px-4 py-6">
            <div className="flex flex-1 items-center justify-center">
              <div className="relative max-w-[20rem] xs:max-w-[12rem] mb-5">
                <img className="relative w-full mx-auto z-20" src="./farm.png" />
                <div className="farm-light blinking-container"></div>
                <div className="farm-small-light blinking-container-small z-20"></div>
              </div>
            </div>
            
            <div className="flex flex-col items-center h-16 px-4">
              { counter && (
                <div className="w-full text-center">
                  <div className="text-lg xs:text-base font-medium mb-1">Earned: {getBalance()}</div>
                  <div className="w-full progress-overlay rounded-xl shadow-sm overflow-hidden p-1">
                    <div className="relative h-1 flex items-center justify-center">
                      <div className="absolute top-0 bottom-0 left-0 rounded-lg bg-orange-500" style={getProgressStyle()}></div>
                    </div>
                  </div> 
                  <div className="text-base sm:text-base xs:text-sm font-light mt-1">Time left: {counter}</div>
                </div>
              ) }

              { isAvailableToEarn(gameStore?.farmingStatus) && (
                <button className="claim-button text-lg" onClick={startFarming}>Earn GTon</button>
              ) }
              { isAvailableToGrab(gameStore?.farmingStatus) && (
                <button className="claim-button text-lg" onClick={grab}>Grab {getBalance()}</button> 
              ) }
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}));



export default Home;
